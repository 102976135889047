<template>
  <div>
    <b-modal
      ref="modal-gestacional-update"
      hide-footer
      centered
      id="gestacional-update"
      title="Atualizar Ultrassonografia Gestacional"
    >
      <div>
        <validation-observer ref="observer">
          <form @submit.prevent="atualizarGestacional">
            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">
                  Data da Ultrassonografia (Obrigatório)
                </h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Data da Ultrassonografia"
                  rules="required"
                >
                  <v-text-field
                    type="Date"
                    v-model="dataGestacional"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <h6 style="color: grey">IG DUM (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="IG DUM"
                  rules="required"
                >
                  <v-text-field
                    v-model="igDum"
                    :error-messages="errors"
                    color="red"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">IG USG (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="IG USG"
                  rules="required"
                >
                  <v-text-field
                    v-model="igUsg"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Peso fetal (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Peso fetal"
                  rules="required"
                >
                  <v-text-field
                    v-model="pesoFetal"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Placenta (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Placenta"
                  rules="required"
                >
                  <v-text-field
                    v-model="placenta"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Líquido (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Líquido"
                  rules="required"
                >
                  <v-text-field
                    v-model="liquido"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Outros (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Outros"
                  rules="required"
                >
                  <v-text-field
                    v-model="outros"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal3"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              style="float: right"
              @click="atualizarGestacional"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Atualizar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>
    <b-modal
      ref="modal-gestacional"
      hide-footer
      centered
      id="gestacional-1"
      title="Cadastrar Ultrassonografia Gestacional"
    >
      <div>
        <validation-observer ref="observer">
          <form @submit.prevent="cadastrarGestacional">
            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">
                  Data da Ultrassonografia (Obrigatório)
                </h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Data da Ultrassonografia"
                  rules="required"
                >
                  <v-text-field
                    type="Date"
                    v-model="dataGestacional"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <h6 style="color: grey">IG DUM (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="IG DUM"
                  rules="required"
                >
                  <v-text-field
                    v-model="igDum"
                    :error-messages="errors"
                    color="red"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">IG USG (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="IG USG"
                  rules="required"
                >
                  <v-text-field
                    v-model="igUsg"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Peso fetal (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Peso fetal"
                  rules="required"
                >
                  <v-text-field
                    v-model="pesoFetal"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Placenta (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Placenta"
                  rules="required"
                >
                  <v-text-field
                    v-model="placenta"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Líquido (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Líquido"
                  rules="required"
                >
                  <v-text-field
                    v-model="liquido"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">Outros (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Outros"
                  rules="required"
                >
                  <v-text-field
                    v-model="outros"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal2"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              style="float: right"
              @click="cadastrarGestacional"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>
    <b-modal
      ref="filter-modal"
      hide-footer
      centered
      id="modal-examesData"
      title="Filtrar exames por intervalo de data"
    >
      <div class="row">
        <div class="col-md-5">
          <v-text-field v-model="consultaDataUm" type="Date"></v-text-field>
        </div>
        <div class="col-md-5">
          <v-text-field v-model="consultaDataDois" type="Date"></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn
            @click="consultarPorIntervalo"
            v-b-tooltip.hover.top="'Filtrar'"
            color="#1dd1a1"
            class="mr-4 mb-2 mt-4"
          >
            <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
          >
        </div>
      </div>
    </b-modal>

    <!--begin::aa-->
    <div class="row">
      <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
        <h3 style="float: left" class="textTitile mt-4 mb-4">
          Ultrassonografia Gestacional
        </h3>
        <b-button
          @click="resetarCampos"
          variant="success"
          style="
            font-size: 1.2rem;
            margin-left: 0px;
            margin-top: 0.2rem;
            border-radius: 0px;
            border: 2px solid white;
            color: white;
          "
          v-b-modal.gestacional-1
          >Cadastrar ultrassonografia</b-button
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-4">
        <v-toolbar style="height: 6rem; display: none">
          <v-toolbar-title
            ><v-btn
              @click="consultarUltra"
              color="#1dd1a1"
              class="mr-4 mb-2 mt-6"
            >
              <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
            ></v-toolbar-title
          >

          <v-text-field
            @keyup.enter.native="consultarUltra"
            v-model="consulta"
            class="mt-14"
            label="Qual ultrassonografia você está procurando?"
            color="green"
            filled
            solo-inverted
          ></v-text-field>
        </v-toolbar>

        <div class="row mt-6">
          <div
            style="
              display: flex;
              align-itens: center;
              justify-content: space-between;
            "
            class="col-md-12"
          >
            <b-tooltip :target="`tooltip3-target`" triggers="hover">
              Página anterior
            </b-tooltip>
            <b-tooltip :target="`tooltip4-target`" triggers="hover">
              Próxima página
            </b-tooltip>
            <div class="mt-2">
              <h3>Página {{ this.page2 }} de {{ this.totalPages2 }}</h3>
            </div>
            <div>
              <v-btn
                :disabled="this.page2 == 0 || this.page2 == 1 ? true : false"
                @click="anteriorPage2"
                id="tooltip3-target"
                color="#1dd1a1"
                class="mr-1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-left</v-icon
                ></v-btn
              >
              <v-btn
                :disabled="
                  this.page2 == this.totalPages2 || this.totalPages2 == 0
                    ? true
                    : false
                "
                @click="proximoPage2"
                id="tooltip4-target"
                color="#1dd1a1"
              >
                <v-icon center small color="#FFF"
                  >fas fa-arrow-right</v-icon
                ></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-6">
      <div class="col-md-12">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">Data</th>
                <th class="">ID DUM</th>
                <th class="">IG USG</th>
                <th class="">Peso fetal</th>
                <th class="">Placenta</th>
                <th class="">Líquido</th>
                <th class="">Outros</th>
                <th class="">Opções</th>
              </tr>
            </thead>

            <tbody v-for="gestacao in tempGestacionais" :key="gestacao.id">
              <tr>
                <td>
                  {{ gestacao.data }}
                </td>

                <td>
                  {{ gestacao.ig_dum }}
                </td>

                <td>
                  {{ gestacao.ig_usg }}
                </td>

                <td>
                  {{ gestacao.peso_fetal }}
                </td>

                <td>
                  {{ gestacao.placenta }}
                </td>

                <td>
                  {{ gestacao.liquido }}
                </td>

                <td>
                  {{ gestacao.outros }}
                </td>

                <td>
                  <v-btn
                    @click="sendDadosModel(gestacao.id)"
                    v-b-modal.gestacional-update
                    v-b-tooltip.hover.top="'Atualizar ultrassonografia'"
                    class="ml-4 mb-2 mt-4"
                    color="#1dd1a1"
                  >
                    <span style="color: white"></span>
                    <v-icon center small color="#FFF"
                      >fas fa-edit</v-icon
                    ></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div
          v-if="tempGestacionais.length == 0"
          class="d-flex flex-column-fluid flex-center mt-12"
        >
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <!--begin::Dashboard-->
      <div class="row">
        <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
          <h3 style="float: left" class="textTitile mt-4 mb-4">
            Histórico de Exames
          </h3>
        </div>
        <div class="col-md-12 mt-4">
          <v-toolbar style="height: 6rem">
            <v-toolbar-title
              ><v-btn
                @click="consultaExamesResultado"
                color="#1dd1a1"
                class="mr-4 mb-2 mt-6"
              >
                <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
              ></v-toolbar-title
            >

            <v-text-field
              @keyup.enter.native="consultaExamesResultado"
              v-model="consulta"
              class="mt-14"
              label="Qual exame você está procurando?"
              color="green"
              filled
              solo-inverted
            ></v-text-field>

            <v-btn
              v-b-modal.modal-examesData
              v-b-tooltip.hover.top="'Filtrar por intervalo'"
              class="ml-4 mb-2 mt-6"
              color="#1dd1a1"
            >
              <span style="color: white"></span>
              <v-icon center small color="#FFF"
                >fas fa-calendar-alt</v-icon
              ></v-btn
            >
          </v-toolbar>

          <div class="row mt-6">
            <div
              style="
                display: flex;
                align-itens: center;
                justify-content: space-between;
              "
              class="col-md-12"
            >
              <b-tooltip :target="`tooltip3-target`" triggers="hover">
                Página anterior
              </b-tooltip>
              <b-tooltip :target="`tooltip4-target`" triggers="hover">
                Próxima página
              </b-tooltip>

              <div class="mt-2">
                <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
              </div>
              <div>
                <v-btn
                  :disabled="this.page == 0 || this.page == 1 ? true : false"
                  @click="anteriorPage"
                  id="tooltip3-target"
                  color="#1dd1a1"
                  class="mr-1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-left</v-icon
                  ></v-btn
                >
                <v-btn
                  :disabled="
                    this.page == this.totalPages || this.totalPages == 0
                      ? true
                      : false
                  "
                  @click="proximoPage"
                  id="tooltip4-target"
                  color="#1dd1a1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-right</v-icon
                  ></v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Descrição</th>

                  <th class="">Data de solicitação</th>
                  <th class="">Data de Resultado</th>
                  <th class="">Descrição do resultado</th>
                  <th class="">Opções</th>
                </tr>
              </thead>

              <tbody v-for="exame in tempExames" :key="exame.id">
                <tr>
                  <td>
                    <input
                      disabled
                      style="
                        border: none;
                        height: 3rem;
                        background-color: white;
                        border-radius: 5px;
                        padding: 1rem;
                      "
                      :value="exame.exame.descricao"
                      v-on:input="handleDescricao($event)"
                    />
                  </td>

                  <td>
                    <input
                      disabled
                      type="date"
                      style="
                        border: none;
                        height: 3rem;
                        background-color: white;
                        border-radius: 5px;
                        padding: 1rem;
                      "
                      :value="exame.solicitacao_exame_data"
                      v-on:input="hadnleDataSolicitacao($event)"
                    />
                  </td>

                  <td>
                    <input
                      type="date"
                      style="
                        border: none;
                        height: 3rem;
                        background-color: white;
                        border-radius: 5px;
                        padding: 1rem;
                      "
                      :value="exame.data"
                      v-on:input="handleDataResultado($event)"
                    />
                  </td>

                  <td>
                    <input
                      style="
                        border: none;
                        height: 3rem;
                        background-color: white;
                        border-radius: 5px;
                        padding: 1rem;
                      "
                      :value="exame.resultado"
                      v-on:input="handleDescricaoResultado($event)"
                    />
                  </td>

                  <td>
                    <v-btn
                      v-b-tooltip.hover.top="'Atualizar status do exame'"
                      @click="
                        updateExame(exame.id, exame.solicitacao_exame_data)
                      "
                      color="#1dd1a1"
                      class="mr-2 mb-2 mt-2"
                    >
                      <v-icon center small color="#FFF"
                        >fas fa-edit</v-icon
                      ></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            v-if="tempExames.length == 0"
            class="d-flex flex-column-fluid flex-center mt-12"
          >
            <a href="#" class="text-center mb-10">
              <img
                src="media/novaImagens/logo.png"
                class="max-h-200px"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>

    <!--begin::prontuario-->
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="row">
          <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
            <h3 style="float: left" class="textTitile mt-4 mb-4">
              Solicitar Exames
            </h3>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-1 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Exames Sanguíneos</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn
                        @click="
                          solitarExames(selectedBioquimica, 'Exames Sanguíneos')
                        "
                        class="textStyle mr-6"
                        tile
                        color="#1dd1a1"
                      >
                        <span style="color: white">Solicitar exames</span>
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedBioquimica"
                        :options="bioquimicaExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-2 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Hematologia</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn
                        class="textStyle mr-6"
                        tile
                        color="#1dd1a1"
                        @click="
                          solitarExames(selectedHematologia, 'Hematologia')
                        "
                      >
                        <span style="color: white">Solicitar exames</span>
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedHematologia"
                        :options="HematologiaExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-3 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Microbiologia e Imunologia</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn
                        @click="
                          solitarExames(
                            selectedMicro,
                            'Microbiologia e Imunologia'
                          )
                        "
                        class="textStyle mr-6"
                        tile
                        color="#1dd1a1"
                      >
                        <span style="color: white">Solicitar exames</span>
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedMicro"
                        :options="microExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-4 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Hormônios</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-4"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn
                        @click="solitarExames(selectedHormonions, 'Hormônios')"
                        class="textStyle mr-6"
                        tile
                        color="#1dd1a1"
                      >
                        <span style="color: white">Solicitar exames</span>
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedHormonions"
                        :options="hormonionsExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-5 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Fezes</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-5"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn
                        @click="solitarExames(selectedFezes, 'Fezes')"
                        class="textStyle mr-6"
                        tile
                        color="#1dd1a1"
                      >
                        <span style="color: white">Solicitar exames</span>
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedFezes"
                        :options="fezesExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-6 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Urinálise</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-6"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn class="textStyle mr-6" tile color="#1dd1a1">
                        <span
                          @click="solitarExames(selectedUrina, 'Urinálise')"
                          style="color: white"
                          >Solicitar exames</span
                        >
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedUrina"
                        :options="urinaExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-md-12 mt-6">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div v-b-toggle.accordion-7 class="boxExame">
                    <span style="margin-left: 2rem; font-size: 1.2rem"
                      >Outros</span
                    >
                  </div>
                </b-card-header>
                <b-collapse
                  id="accordion-7"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="boxHeaderExame">
                      <h4 size="lg" value="all" disabled>
                        Selecione os exames abaixo para solicitar
                      </h4>
                      <v-btn
                        @click="solitarExames(selectedOutros, 'Outros')"
                        class="textStyle mr-6"
                        tile
                        color="#1dd1a1"
                      >
                        <span style="color: white">Solicitar exames</span>
                      </v-btn>
                    </div>
                    <hr />

                    <b-form-group>
                      <b-form-checkbox-group
                        size="lg"
                        v-model="selectedOutros"
                        :options="outrosExames"
                        name="flavour-2a"
                        stacked
                      ></b-form-checkbox-group>

                      <div class="row">
                        <div class="col-md-6">
                          <v-text-field v-model="novoExameOutro"></v-text-field>
                        </div>
                        <div class="col-md-6">
                          <v-btn
                            @click="adicionarNovoExameOutro"
                            style="color: white"
                            color="#1dd1a1"
                            class="mt-4"
                          >
                            adicionar novo exame</v-btn
                          >
                        </div>
                      </div>
                    </b-form-group>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::prontuario-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import moment from "moment";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido",
});

export default {
  name: "prontuario",
  components: { ValidationProvider, ValidationObserver },
  props: {
    dados: Object,
  },

  async created() {
    const { data: listGestacao } = await ambulatorio.listGestacionalPaged(
      this.dados.dados_prontuario.id,
      1
    );
    this.totalPages2 = listGestacao.total_pages;
    this.gestacionais = listGestacao.ultrassonografia_gestacional;
    this.tempGestacionais = listGestacao.ultrassonografia_gestacional;

    const { data } = await ambulatorio.listExamesResultadoPaged(
      this.dados.dados_prontuario.id,
      1
    );

    this.exames = data.exame_resultado;
    this.tempExames = data.exame_resultado;
    this.totalPages = data.total_pages;

    try {
      const bioquimica = await ambulatorio.getExamePorTipo("Bioquímica");
      bioquimica.data.exame.map((exame) => {
        this.bioquimicaExames = [
          ...this.bioquimicaExames,
          { text: exame.descricao, value: exame.id },
        ];
      });

      const Hematologia = await ambulatorio.getExamePorTipo("Hematologia");
      Hematologia.data.exame.map((exame) => {
        this.HematologiaExames = [
          ...this.HematologiaExames,
          { text: exame.descricao, value: exame.id },
        ];
      });

      const micro = await ambulatorio.getExamePorTipo(
        "Microbiologia e Imunologia"
      );
      micro.data.exame.map((exame) => {
        this.microExames = [
          ...this.microExames,
          { text: exame.descricao, value: exame.id },
        ];
      });

      const hormonio = await ambulatorio.getExamePorTipo("Hormônio");
      hormonio.data.exame.map((exame) => {
        this.hormonionsExames = [
          ...this.hormonionsExames,
          { text: exame.descricao, value: exame.id },
        ];
      });

      const fezes = await ambulatorio.getExamePorTipo("Fezes");
      fezes.data.exame.map((exame) => {
        this.fezesExames = [
          ...this.fezesExames,
          { text: exame.descricao, value: exame.id },
        ];
      });

      const urina = await ambulatorio.getExamePorTipo("Urinálise");
      urina.data.exame.map((exame) => {
        this.urinaExames = [
          ...this.urinaExames,
          { text: exame.descricao, value: exame.id },
        ];
      });

      const outros = await ambulatorio.getExamePorTipo("Outros");
      outros.data.exame.map((exame) => {
        this.outrosExames = [
          ...this.outrosExames,
          { text: exame.descricao, value: exame.id },
        ];
      });
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      novoExameOutro: "",
      descricaoResultadoExame: "",
      dataSolicitacaoAtual: "",
      page2: 1,
      totalPages2: "",
      idGestacionalAtual: "",
      tempGestacionais: [],
      gestacionais: [],
      dataGestacional: "",
      igDum: "",
      igUsg: "",
      pesoFetal: "",
      placenta: "",
      liquido: "",
      outros: "",
      consultaDataUm: "",
      consultaDataDois: "",
      selectedBioquimica: [],
      bioquimicaExames: [],
      HematologiaExames: [],
      selectedHematologia: [],
      microExames: [],
      selectedMicro: [],
      hormonionsExames: [],
      selectedHormonions: [],
      fezesExames: [],
      selectedFezes: [],
      urinaExames: [],
      selectedUrina: [],
      outrosExames: [],
      selectedOutros: [],
      descricao: "",
      dataResultado: "",
      dataSolicitacao: "",
      totalPages: 1,
      page: 1,
      exames: [],
      tempExames: [],
      consulta: "",
    };
  },

  methods: {
    adicionarNovoExameOutro: async function () {
      try {
        const data = {
          exame: {
            descricao: this.novoExameOutro,
            tipo: "Outros",
          },
        };

        await ambulatorio.adicionarExame(data);

        const outros = await ambulatorio.getExamePorTipo("Outros");
        this.outrosExames = [];
        outros.data.exame.map((exame) => {
          this.outrosExames = [
            ...this.outrosExames,
            { text: exame.descricao, value: exame.id },
          ];
        });

        Swal.fire({
          title: "",
          text: `Exame ${this.novoExameOutro} adicionado com sucesso`,
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: "",
          text: "Erro ao adicionar exame, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    solitarExames: async function (examesVector, tipo) {
      try {
        const data = {
          solicitacao_exame: {
            prontuario_id: this.dados.dados_prontuario.id,
            exames_id: examesVector,
          },
        };

        await ambulatorio.solicitarExames(data);

        Swal.fire({
          title: "",
          text: `Exame de ${tipo} solicitado com sucesso`,
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        const response = await ambulatorio.listExamesResultadoPaged(
          this.dados.dados_prontuario.id,
          1
        );

        this.exames = response.data.exame_resultado;
        this.tempExames = response.data.exame_resultado;
        this.totalPages = response.data.total_pages;
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar solicitação, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    handleDescricao: function (e) {
      this.descricao = e.target.value;
    },
    hadnleDataSolicitacao: function (e) {
      this.dataSolicitacao = e.target.value;
    },

    handleDescricaoResultado: function (e) {
      this.descricaoResultadoExame = e.target.value;
    },

    handleDataResultado: function (e) {
      this.dataResultado = e.target.value;
    },
    consultaExamesResultado: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const { data } = await ambulatorio.listExamesResultadoPaged(
          this.dados.dados_prontuario.id,
          1
        );
        this.exames = data.exame_resultado;
        this.tempExames = data.exame_resultado;
        this.totalPages = data.total_pages;
      } else {
        const data = {
          filter: {
            "exames.descricao": this.consulta,
          },
          required: {
            "exames_resultados.prontuario_id": this.dados.dados_prontuario.id,
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaResultadoExames(data);

        this.exames = response.data.resultado;
        this.tempExames = response.data.resultado;
        this.totalPages = response.data.total_pages;
      }
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const { data } = await ambulatorio.listExamesResultadoPaged(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.exames = data.exame_resultado;
          this.tempExames = data.exame_resultado;
          this.totalPages = data.total_pages;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const { data } = await ambulatorio.listExamesResultadoPaged(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.exames = data.exame_resultado;
          this.tempExames = data.exame_resultado;
          this.totalPages = data.total_pages;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },

    proximoPage2: async function () {
      try {
        this.page2++;
        if (this.page2 <= this.totalPages2) {
          const { data } = await ambulatorio.listGestacionalPaged(
            this.dados.dados_prontuario.id,
            this.page2
          );
          this.totalPages2 = data.total_pages;
          this.gestacionais = data.ultrassonografia_gestacional;
          this.tempGestacionais = data.ultrassonografia_gestacional;
        } else {
          this.page2--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage2: async function () {
      try {
        this.page2--;
        if (this.page2 >= 1) {
          const { data } = await ambulatorio.listGestacionalPaged(
            this.dados.dados_prontuario.id,
            this.page2
          );
          this.totalPages2 = data.total_pages;
          this.gestacionais = data.ultrassonografia_gestacional;
          this.tempGestacionais = data.ultrassonografia_gestacional;
        } else {
          this.page2++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateExame(idExame, dataSolicitacao) {
      try {
        const data = {
          exame_resultado: {
            data: this.dataResultado,
            resultado: this.descricaoResultadoExame,
            exame_id: idExame,
          },
        };

        if (moment(dataSolicitacao).isAfter(this.dataResultado)) {
          Swal.fire({
            title: "",
            text: "Data do resultado precisa ser maior que da solicitação do exame",
            icon: "error",
            confirmButtonColor: "red",
            heightAuto: false,
          });
          return;
        }

        await ambulatorio.updateResultadoExames(idExame, data);

        Swal.fire({
          title: "",
          text: `Resultado do Exame atualizado com sucesso`,
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    cadastrarGestacional: async function () {
      try {
        const data = {
          ultrassonografia_gestacional: {
            data: this.dataGestacional,
            ig_dum: this.igDum,
            ig_usg: this.igUsg,
            peso_fetal: this.pesoFetal,
            placenta: this.placenta,
            liquido: this.liquido,
            outros: this.outros,
            prontuario_id: this.dados.dados_prontuario.id,
          },
        };

        await ambulatorio.cadastrarGestacional(data);

        const { data: listGestacao } = await ambulatorio.listGestacionalPaged(
          this.dados.dados_prontuario.id,
          1
        );
        this.totalPages2 = listGestacao.total_pages;
        this.gestacionais = listGestacao.ultrassonografia_gestacional;
        this.tempGestacionais = listGestacao.ultrassonografia_gestacional;

        Swal.fire({
          title: "",
          text: `Ultrassonografia gestacional cadastrada com sucesso`,
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        this.hideModal2();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    atualizarGestacional: async function () {
      try {
        const data = {
          ultrassonografia_gestacional: {
            data: this.dataGestacional,
            ig_dum: this.igDum,
            ig_usg: this.igUsg,
            peso_fetal: this.pesoFetal,
            placenta: this.placenta,
            liquido: this.liquido,
            outros: this.outros,
            prontuario_id: this.dados.dados_prontuario.id,
          },
        };

        await ambulatorio.atualizarGestacional(data, this.idGestacionalAtual);

        const { data: listGestacao } = await ambulatorio.listGestacionalPaged(
          this.dados.dados_prontuario.id,
          1
        );
        this.totalPages2 = listGestacao.total_pages;
        this.gestacionais = listGestacao.ultrassonografia_gestacional;
        this.tempGestacionais = listGestacao.ultrassonografia_gestacional;

        Swal.fire({
          title: "",
          text: `Ultrassonografia gestacional atualizada com sucesso`,
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        this.hideModal3();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },

    hideModal() {
      this.$refs["filter-modal"].hide();
    },

    hideModal2() {
      this.$refs["modal-gestacional"].hide();
    },

    hideModal3() {
      this.$refs["modal-gestacional-update"].hide();
    },

    consultarPorIntervalo: async function () {
      try {
        const data = {
          filter: {
            created_at: "",
            "pessoas.nome": this.dados.dados_paciente.pessoa.nome,
            "prontuarios.numero": this.dados.dados_paciente.prontuario,
            "pessoas.cpf": this.dados.dados_paciente.pessoa.cpf,
          },
          required: {
            created_at: [this.consultaDataUm, this.consultaDataDois],
            "exames_resultados.prontuario_id": this.dados.dados_prontuario.id,
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaResultadoExames(data);

        this.exames = response.data.resultado;
        this.tempExames = response.data.resultado;
        this.totalPages = response.data.total_pages;

        this.hideModal();
      } catch (error) {
        console.log(error);
      }
    },

    sendDadosModel: async function (idGestacional) {
      try {
        this.idGestacionalAtual = idGestacional;

        const {
          data: { ultrassonografia_gestacional: dado },
        } = await ambulatorio.showGestacionalAtual(this.idGestacionalAtual);

        this.dataGestacional = dado.data;
        this.igDum = dado.ig_dum;
        this.igUsg = dado.ig_usg;
        this.pesoFetal = dado.peso_fetal;
        this.placenta = dado.placenta;
        this.liquido = dado.liquido;
        this.outros = dado.outros;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário " }]);
  },
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}

.btn.btn-success {
  float: right;
  color: #ffffff;
  background-color: #20c997 !important;
}

.btn.btn-success:hover {
  color: #ffffff;
  background-color: #23a881 !important;
}

.boxExame {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: right;
}

.form-group label {
  font-size: 1.5rem !important;
  font-weight: 300;
  color: #3f4254;
}

.boxHeaderExame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #20c997 !important;
  background-color: #20c997 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
